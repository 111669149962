import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { styled, Typography, Grid } from '@mui/material';
import DALogo1 from '../../assets/DALogo1.png'

// import logo from '../../assets/altair_logo.png'

// const StyleDivH1 = styled('div')({
//     color: '#030303',
//     fontSize: '16px',
//     fontFamily: 'Poppins',
//     fontWeight: 700,
//     lineHeight: '20px',
// })
const StyleDivH2 = styled('div')({
    color: '#030303',
    fontSize: '16px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
})

const Header = (props) => {
    // const navigate = useNavigate();
    const navigate = useNavigate();

    return (
        <>

            <div className='toolbar' role="banner">
                {/* 
        
                <div style={{ flex: 2 }}>
                    <div style={{  width: '100%', padding: '10px' }}> */}
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               
                    <Grid item lg={2.5} md={2.5} sm={6} xs={12} alignItems="left" style={{display:'flex',alignItems:'center'}}>

                        <img
                            width="75px"
                            alt="Comapny Logo"
                            src={DALogo1}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/home')}
                        />
                        <div>
                        <Typography variant="body" pb={2} style={{
                            color: '#14AB3F',
                            fontFamily: "Kaushan Script,Sans-serif",
                            fontSize: "20px",
                            fontWeight: "bold",
                            // lineHeight: "1.3em",
                            textAlign: 'left'
                        }}>Dhruv Ayaansh Enterprises</Typography>
                         <Typography variant="h3"  style={{
                                    color: '#d44141',
                                    fontFamily: "Kaushan Script,Sans-serif",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    // lineHeight: "1.3em",
                                    textAlign: 'right'
                                }}>Exports & Imports</Typography>
                        </div>

               

                    </Grid>
                    <Grid item lg={9.5} md={9.5} sm={6} xs={12} alignItems="left">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item  lg={6} sm={6} xs={12} alignItems="left" style={{ backgroundColor: "#007e28" }}>
                            <div style={{ display: 'flex', color: 'white' }}>
                                <PhoneIcon />
                                <StyleDivH2><a href="tel:+919701801908" style={{ color: 'white' }}>+91 9701801908</a></StyleDivH2>
                            </div>
                        </Grid>
                        <Grid item  lg={6} sm={6} xs={12} alignItems="left" style={{ backgroundColor: "#007e28" }}>
                            <div style={{ display: 'flex', color: 'white' }}>
                                <EmailIcon />
                                <a href="mailto:dhruvayaanshep@gmail.com">dhruvayaanshep@gmail.com</a>
                                {/* <StyleDivH1>E-Mail</StyleDivH1> */}
                            </div>
                        </Grid>
                        <Grid item lg={1} md={2} sm={2} xs={4}>
                            <Typography className='optionP' onClick={() => navigate('/products')}> Products</Typography>
                        </Grid>
                        <Grid item lg={1} md={2} sm={2} xs={4}>
                            <Typography className='optionP' onClick={() => navigate('/about')}> About</Typography>
                        </Grid>
                        <Grid item lg={1} md={2} sm={2} xs={4}>
                            <Typography className='optionP' onClick={() => navigate('/contact')}> Contact</Typography>
                        </Grid>
                </Grid>
           
                    </Grid>


                </Grid>
                {/* </div> */}
                {/* <div className='optionsContainer'>
                        <div className='optionDiv'>
                            <p className="optionP">
                                <span className='optionSpan' onClick={() => navigate('/products')}>Products</span>
                            </p>
                        </div>
                        <div className='optionDiv'>
                            <p className="optionP">
                                <span className='optionSpan' onClick={() => navigate('/about')}>About</span>
                            </p>
                        </div>
                        <div className='optionDiv'>
                            <p className="optionP">
                                <span className='optionSpan' onClick={() => navigate('/contact')}>Contact Us</span>
                            </p>
                        </div>
                    </div> */}

                {/* </div> */}
            </div>
        </>

    )
}

export default Header