import React,{useEffect, useState} from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea,Button } from '@mui/material';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import './Products.css';
import { chilliDetails } from "../../data/chilliDetails";
import { useNavigate } from 'react-router-dom';
import chilliBg1 from '../../assets/chillibg1.jpeg';


// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));
  

const Product =(props)=>{
    const navigate = useNavigate();
    const [items, setItems] = useState([ ])

    useEffect(()=>{
        let chillidetails = chilliDetails();
        setItems(chillidetails)
    },[])

    return (
<>


<Box
                sx={{
                    backgroundImage: `url(${chilliBg1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "black",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%", md: "40%" },
                        padding: { xs: 3, sm: 2, md: 20 },
                    }}
                >
                    <Box sx={{ background: "white", opacity: "0.8", justifyContent: "center" }}>
                        <Typography variant="h4" align="center" color="tomato">
                            Our Chilli Products
                        </Typography>
                        <Typography variant="h3" align="center" pb={8}>
                            Dhruv Ayaansh Enterprises
                        </Typography>
                    </Box>
                </Box>

                
            </Box>

<div className="productDetailscontainer">

<Box sx={{ flexGrow: 1 }}>


<Grid container spacing={3}>
{
          items.map((item, i) => {
              return (
                  <Grid item lg={3} md={3} sm={3} xs={6} key={item.name}>
                  {/* <Item> */}
                      <Card>
              <CardActionArea onClick={()=>navigate(`/product/${item.name}`)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={item.imgUrl}
                  alt={item.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{textTransform:'uppercase'}}>   
                    {item.name}
                  </Typography>
                  <Button  style={{background: '#fb641b'}} variant="contained" onClick={()=>navigate(`/product/${item.name}`)}>Read More </Button>
                  {/* <Typography variant="body2" color="text.secondary">
                   {item.description}
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
            {/* </Item> */}
                </Grid>
              )
          })
      }

  {/* <Grid item xs>
    <Item>xs=6</Item>
  </Grid>
  <Grid item xs>
    <Item>xs</Item>
  </Grid> */}
</Grid>
</Box>

</div>




    {/* <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img1}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img1}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card> */}
</>
    )

}

export default Product