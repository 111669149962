import React, { useEffect, useState } from "react";
import { styled, Box, Typography, Grid, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { chilliDetails } from "../../data/chilliDetails";
import { tableCellClasses } from '@mui/material/TableCell';
import { useNavigate } from "react-router-dom";
import chillibg1 from '../../assets/chillibg1.jpeg';
// import Paper from '@mui/material/Paper';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CertificateGrid from '../../Components/CertificateGrid/CertificateGrid'

// import { Image } from "@mui/icons-material";


// const Image = styled('img')({
//     padding: '15px 20px',
//     border: '1px solid #f0f0f0',
//     width: '100%',
//     height: '300px',
//     objectFit: 'cover',
//     objectPosition: '80% 100%'
// });

// const Component = styled(Box)`
//     margin-top: 55px;
//     background: #F2F2F2;
// `;

const Container = styled(Grid)(({ theme }) => ({
    background: '#FFFFFF',
    display: 'flex',
    textAlign: 'left',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
        margin: 0
    }
}))

const RightContainer = styled(Grid)`
    text-align: justify;
    // margin-top: 50px;
    margin-bottom: 50px;
    & > p {
        margin-top: 10px;
    }
`;

const StyleDivH1 = styled('div')({
    color: '#030303',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '20px',
})

const StyleDivH2 = styled('div')({
    color: '#030303',
    fontSize: '16px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
})

// const Item = styled(Paper)(({ theme }) => ({
//     // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     // ...theme.typography.body2,
//     // padding: theme.spacing(1),
//     // textAlign: 'center',
//     // color: theme.palette.text.secondary,
// }));

// const StyleImgBg = styled.div`
//     text-align: justify;
// `;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));




const Contact = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        let chillidetails = chilliDetails()
        setProducts(chillidetails)
    }, [])

    return (
        <div>

            <Box
                sx={{
                    backgroundImage: `url(${chillibg1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "black",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%", md: "40%" },
                        padding: { xs: 3, sm: 2, md: 20 },
                    }}
                >
                    <Box sx={{ background: "white", opacity: "0.8", justifyContent: "center" }}>
                        <Typography variant="h4" align="center" color="tomato">
                            Get In Touch
                        </Typography>
                        <Typography variant="h3" align="center" pb={8}>
                            Dhruv Ayaansh Enterprises
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Container container spacing={2} p={5}>
                {/* <Grid xs={12}>
                    <Image src={chillibg1}/>
                </Grid> */}
                <Grid item lg={8} md={8} sm={8} xs={12} >
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            borderRadius: '2px',
                            height: 350,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={3} md={4} sm={6} xs={12} style={{  display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'}}>
                                <div style={{
                                    // textAlign: 'left',
                                    // padding: '10%',
                                  
                                }}>
                                    <div style={{display:'flex'}}>
                                        <PhoneIcon/>
                                        <StyleDivH1>Phone</StyleDivH1>
                                    </div>
                                    <div>
                                        <StyleDivH2><a href="tel:+919701801908">+91 9701801908</a></StyleDivH2>
                                    </div>

                                </div>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12} style={{  display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'}}>

                                <div >
                                    <div style={{display:'flex'}}>
                                    <EmailIcon/>
                                        <StyleDivH1>E-Mail</StyleDivH1>
                                    </div>
                                    <div>
                                      
                                        <a href="mailto:dhruvayaanshep@gmail.com">dhruvayaanshep@gmail.com</a>
                                    </div>

                                </div>

                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12} style={{  display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'}}>

                                <div >
                                    <div style={{display:'flex'}}>
                                        <HomeRoundedIcon/>
                                        <StyleDivH1>Address</StyleDivH1>
                                    </div>
                                    <div>
                                        <StyleDivH2>11-14-131, Road No. 15A, "O" City,</StyleDivH2>
                                        <StyleDivH2>Warangal, TS - 506002, INIDA.</StyleDivH2>
                                    </div>

                                </div>

                            </Grid>
                            <Grid item xs={12}>
                           
                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3794.3619140387036!2d79.64524507589722!3d18.008400084616987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDAwJzMwLjIiTiA3OcKwMzgnNTIuMiJF!5e0!3m2!1sen!2sin!4v1707481332544!5m2!1sen!2sin" width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Address"></iframe>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <RightContainer item lg={4} md={4} sm={8} xs={12}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Products</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row) => (
                                <StyledTableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    onClick={() => navigate(`/product/${row.name}`)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </RightContainer>
            </Container>

            <CertificateGrid/>



        </div>
    )

}

export default Contact