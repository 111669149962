
import {Typography, Table, TableBody, TableRow, TableCell, styled } from '@mui/material';



// const SmallText = styled(Box)`
//     font-size: 14px;
//     vertical-align: baseline;
//     & > p {
//         font-size: 14px;
//         margin-top: 10px;
//     }
// `

const ColumnText = styled(TableRow)`
    font-size: 14px;
    vertical-align: baseline;
    & > td {
        font-size: 14px;
        margin-top: 10px;
    }
`

// const StyledBadge = styled(Badge)`
//     margin-right: 10px;
//     color: #00CC00;
//     font-size: 15px;
// `;

const ProductDetail = ({ product }) => {

    
    return (
        <>
            <Table style={{border: '1px solid #f0f0f0'}}>
                <TableBody>
                    <ColumnText>
                        <TableCell  colSpan={2} style={{ fontWeight: 800 }}>Product Details</TableCell>
                        {/* <TableCell style={{ fontWeight: 800 }}></TableCell> */}
                    </ColumnText>
                    {
                        product && product.name?<ColumnText>
                        <TableCell  style={{ color: '#878787' ,width:'200px'}}>Name</TableCell>
                        <TableCell>{product.name}</TableCell>
                    </ColumnText>:null
                    }

                    
                    {
                        product && product.countryOfOrigin?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Country Of Origin</TableCell>
                        <TableCell>{product.countryOfOrigin}</TableCell>
                    </ColumnText>:null
                    }
                    {
                        product && product.cultivationType?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Cultivation Type</TableCell>
                        <TableCell>{product.cultivationType}</TableCell>
                    </ColumnText>:null
                    }

                    {
                        product && product.dryingProcess?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Drying Process</TableCell>
                        <TableCell>{product.dryingProcess}</TableCell>
                    </ColumnText>:null
                    }

                    {
                        product && product.quality?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Quality</TableCell>
                        <TableCell>{product.quality}</TableCell>
                    </ColumnText>:null
                    }

                    {
                        product && product.businessType?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Business Type</TableCell>
                        <TableCell>{product.businessType}</TableCell>
                    </ColumnText>:null
                    }

                    {
                        product && product.Taste?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Taste</TableCell>
                        <TableCell>{product.Taste}</TableCell>
                    </ColumnText>:null
                    }

                    {
                        product && product.specialities?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Specialities</TableCell>
                        <TableCell>{product.specialities}</TableCell>
                    </ColumnText>:null
                    }
                    {
                        product && product.packingType?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Packing Type</TableCell>
                        <TableCell>{product.packingType}</TableCell>
                    </ColumnText>:null
                    }
                    {
                        product && product.color?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Color</TableCell>
                        <TableCell>{product && product.color?product.color:''}</TableCell>
                    </ColumnText>:null
                    }
                    {
                        product && product.skin?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Skin</TableCell>
                        <TableCell>{product && product.skin?product.skin:''}</TableCell>
                    </ColumnText>:null
                    }
                    
                    {
                        product && product.pungencyInSHU?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Pungency In SHU</TableCell>
                        <TableCell>{product.pungencyInSHU}</TableCell>
                        </ColumnText>:null
                    }
                    {
                        product && product.colourInAsta?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Colour In ASTA</TableCell>
                        <TableCell>{product.colourInAsta}</TableCell>
                        </ColumnText>:null
                    }
                    {
                        product && product.length?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Length</TableCell>
                        <TableCell>{product.length}</TableCell>
                        </ColumnText>:null
                    }
                    {
                        product && product.breath?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Breath</TableCell>
                        <TableCell>{product.breath}</TableCell>
                        </ColumnText>:null
                    }
                    {
                        product && product.capsaicinContent?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Capsaicin Content</TableCell>
                        <TableCell>{product.capsaicinContent}</TableCell>
                        </ColumnText>:null
                    }
                    {
                        product && product.moisture?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Moisture</TableCell>
                        <TableCell>{product.moisture}</TableCell>
                        </ColumnText>:null
                    }
                    {/* {
                        product && product.description?<ColumnText>
                        <TableCell style={{ color: '#878787' }}>Description</TableCell>
                        <TableCell>{product && product.description?product.description:''}</TableCell>
                        </ColumnText>:null
                    } */}

                    <ColumnText>
                        <TableCell style={{ color: '#878787' }}>Contact Details</TableCell>
                        <TableCell>
                            
                            <Typography>
                            <span style={{ color: '#2874f0' }}>Sravan Kancha</span></Typography>
                            <Typography>
                            <a href="mailto:dhruvayaanshep@gmail.com">dhruvayaanshep@gmail.com</a> 
                            </Typography>
                           
                            <Typography> <a href="tel:+919701801908">+91 9701801908</a></Typography>
                        </TableCell>
                    </ColumnText>
                    
                </TableBody>
            </Table>
        </>
    )
}

export default ProductDetail;