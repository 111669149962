import { useState, useEffect } from 'react';

import { styled, Box, Grid } from '@mui/material';

import ProductDetail from './ProductDetail';
import ActionItem from './ActionItem';
import { useParams ,useNavigate} from 'react-router-dom';
import { chilliDetails } from '../../data/chilliDetails';
import CertificateGrid from "../../Components/CertificateGrid/CertificateGrid";

// import { getProductById } from '../../service/api';
// import { useDispatch, useSelector } from 'react-redux';

// import { getProductDetails } from '../../redux/actions/productActions';

const Component = styled(Box)`
    margin-top: 10px;
    background: 'white';
`;

const Container = styled(Grid)(({ theme }) => ({
    background: '#FFFFFF',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        margin: 0
    }
}))

const RightContainer = styled(Grid)`
    text-align: justify;
    // margin-top: 50px;
    & > p {
        margin-top: 10px;
    }
`;

const Product = () =>{

    const [productDetails,setProductDetails] = useState();
    const navigate = useNavigate();
    
    const { name } = useParams();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        let chillidetails = chilliDetails()
        let pd = chillidetails && chillidetails.length && chillidetails.filter(c=>c.name===name);
        if(pd && pd.length){
            setProductDetails(pd[0])
        }else{
            navigate('/products')
        }
    }, [name,navigate]);

    return (
        <Component p={5}>
            <Box></Box>
                <Container container> 
                    <Grid item lg={4} md={4} sm={8} xs={12}>
                        <ActionItem product={productDetails}/>
                    </Grid>
                    <RightContainer item lg={8} md={8} sm={8} xs={12}>
                        {/* <Typography>
                             <span style={{ fontSize: 28 }}>  {productDetails && productDetails.name ? productDetails.name :''}</span> 
                        </Typography> */}
                        <ProductDetail product={productDetails}/>
                    </RightContainer>
                </Container>
                <CertificateGrid/>
          
        </Component>
    )

}

export default Product