import React, { useEffect } from 'react';
import './Home.css'
// import img1 from '../../assets/homeimg1.png'
// import img2 from '../../assets/homeimg2.png'
// import HeaderText1 from '../../Components/HeaderText1/HeaderText1';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import MultipleImagesCard from '../../Components/MultipleImagesCard/MultipleImagesCard';
// import SlideShow from '../../Components/SlideShow/SlideShow';
// import HomeCarousel from '../../Components/HomeCarousel/HomeCarousel';
import { Box, Typography, Grid } from '@mui/material';
// import chilliBg1 from '../../assets/chilliBg4.jpg';
import ChilliGrid from '../../Components/ChilliGrid/ChilliGrid';
import iso from '../../assets/iso.png';
import fssai from '../../assets/fssai.jpeg';
import spicesboard from '../../assets/spices-board.png';
import apeda from '../../assets/apeda.jpeg';
import Carousel from 'react-material-ui-carousel';
// import { useNavigate } from 'react-router-dom';
// import img3 from '../../assets/homeimg3.png'
// import SlideShowKenBurns from '../../Components/SlideShowKenBurns/SlideShowKenBurns';
import chilliHomescreen from '../../assets/chillihomescreen.jpg';
import ch1 from '../../assets/ch1.jpg';
import ch2 from '../../assets/ch2.jpg';
import sd1 from '../../assets/sd1.png';
import sd2 from '../../assets/sd2.jpg';
// import worldmap from '../../assets/worldmap.png';
// import DALogo from '../../assets/DALogo.jpeg';
import DALogo1 from '../../assets/DALogo1.png';
import worldmap1 from '../../assets/worldmap2.jpeg';





const Home = (props) => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div className='HomeContainer'>
            {/* <SlideShowKenBurns/> */}
            {/* <HomeCarousel/> */}
            {/* <Box
                sx={{
                    // backgroundImage: `url(${ch1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "black",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    // height: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%", md: "40%" },
                        padding: { xs: 3, sm: 2, md: 20 },
                    }}
                >
                    <Box sx={{ background: "white", opacity: "0.8", justifyContent: "center" }}>

                        <Typography variant="h4" align="center" color="tomato">
                            Welcome To
                        </Typography>
                        <Typography variant="h3" align="center" pb={8}>
                            Dhruv Ayaansh Enterprises
                        </Typography>
                    </Box>
                </Box>
            </Box> */}
            <Box>
                <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item lg={4} md={4} sm={4} xs={4} style={{ height: 'max-content' }}>
                        <img
                            alt="img1"
                            src={chilliHomescreen}
                            style={{ objectFit: 'contain', width: '100%' }}
                        />

                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} style={{ height: 'max-content' }}>
                        <img
                            alt="img1"
                            src={ch2}
                            style={{ objectFit: 'contain', width: '100%' }}
                        />

                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} style={{ height: 'max-content' }}>
                        <img
                            alt="img1"
                            src={ch1}
                            style={{ objectFit: 'contain', width: '100%' }}
                        />

                    </Grid>

                </Grid>
            </Box>

            <Box style={{ marginTop: '20px' }}>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ height: 'max-content' }}>


                        <Carousel  >
                            <div className='carouse-div'>
                                <img
                                    alt="img1"
                                    src={sd1}
                                    style={{ objectFit: 'contain', width: '80%' }}
                                    loading="lazy"
                                />
                            </div>
                            <div className='carouse-div'>
                                <img
                                    alt="img1"
                                    src={sd2}
                                    style={{ objectFit: 'contain', width: '80%' }}
                                    loading="lazy"
                                />
                            </div>

                        </Carousel>


                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <img
                                    alt="img1"
                                    src={DALogo1}
                                    style={{ width: '100%', objectFit: 'contain' }}
                                />

                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                            <Box sx={{
                                backgroundImage: `url(${worldmap1})`,

                                backgroundRepeat: "no-repeat",
                                //   backgroundAttachment: "fixed",
                                backgroundPosition: "center",
                                // backgroundSize: "cover",
                                // height: 400,
                                width: "100%",
                                //   display: "flex",
                                justifyContent: "center",

                            }}>
                                <Typography variant="h3" pb={2} style={{
                                    color: '#14AB3F',
                                    fontFamily: "Kaushan Script,Sans-serif",
                                    fontSize: "62px",
                                    fontWeight: "bold",
                                    lineHeight: "1.3em",
                                    textAlign: 'left'
                                }}>Dhruv Ayaansh Enterprises</Typography>
                                <Typography variant="h3" pb={2} style={{
                                    color: '#d44141',
                                    fontFamily: "Kaushan Script,Sans-serif",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    lineHeight: "1.3em",
                                    textAlign: 'center'
                                }}>Exports & Imports</Typography>


                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption" display="block" gutterBottom style={{
                                textAlign: 'left', color: '#c1c1c1',
                                fontSize: '20px',
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                lineHeight: '24px',
                            }}>
                                Our Specialties
                            </Typography>
                <Typography align='left' pb={2} style={{
                                color: '#030303',
                                fontSize: '20px',
                                fontFamily: 'Poppins',
                                lineHeight: '24px',
                            }}>
                                At Dhruv Ayaansh Enterprises, we take pride in being experts in the export of a diverse range of chilli variants to various countries worldwide. Our commitment to quality, reliability, and customer satisfaction has made us a trusted partner in the international chilli market.
                            </Typography>
                            <Typography align='left' pb={1} style={{
                                color: '#030303',
                                fontSize: '20px',
                                fontFamily: 'Poppins',
                                lineHeight: '24px',
                            }}>
                                With years of experience, we have honed our expertise in exporting premium chilli variants, including S17 Teja,Wonder hot,341,5531,C5,Sannam(334), Tomato, and more. Our in-depth knowledge ensures that you receive the finest chilli products.
                            </Typography>
                </Grid>

                    </Grid>
                        

 


                         
                            {/* <Typography variant="h3" pb={2} style={{
                    textAlign: 'left', color: '#030303',
                    fontSize: '42px',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    lineHeight: '52px',
                }}>Simple and sensational!</Typography> */}

                           






                        {/* <HeaderText1 text="ChilliExpert and the Chilli Market"></HeaderText1> */}

                    </Grid>
                </Grid>
            </Box>





            {/* <SlideShow/> */}
            {/* <MultipleImagesCard/> */}
            {/* <HeaderText1 text="Sravan Kancha"></HeaderText1> */}

            <ChilliGrid />
            <Box style={{ marginTop: '15px' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} >
                        <Typography variant="body" style={{
                            textAlign: 'left', color: '#030303',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            lineHeight: '18px',
                        }}>Have a look at our</Typography>
                        <Typography variant="h3" pb={2} style={{
                            textAlign: 'center', color: 'rgb(0, 126, 40)',
                            fontSize: '28px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            lineHeight: '52px',
                        }}>Certifications</Typography>

                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>

                        <Carousel className='carouse-certificate'>
                            <div className='carouse-div'>
                                <div className='infotextsym'></div>
                                <Typography className='infotext'>Our APEDA certification signifies our compliance with the Agricultural
                                    and Processed Food Products Export Development Authority's guidelines, ensuring the export of
                                    top-notch agricultural products.</Typography>

                                <div className='infotextby' style={{
                                    marginTop: '1px',
                                    textAlign: 'right', color: 'white',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    lineHeight: '18px',
                                }}>APEDA </div>
                            </div>
                            <div className='carouse-div'>
                                <div className='infotextsym'></div>
                                <Typography className='infotext'>Our ISO certification in Food Safety Management reflects our stringent
                                    adherence to international standards, ensuring the highest level of quality and safety in our
                                    products.</Typography>
                                <div className='infotextby' style={{
                                    marginTop: '1px',
                                    textAlign: 'right', color: 'white',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    lineHeight: '18px',
                                }}>ISO: Food Safety Management </div>
                            </div>



                        </Carousel>

                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                <img
                                    alt="img1"
                                    src={iso}
                                    style={{ width: '50%', objectFit: 'contain' }}
                                />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                <img
                                    alt="img1"
                                    src={fssai}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                <img
                                    alt="img1"
                                    src={spicesboard}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                <img
                                    alt="img1"
                                    src={apeda}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>
            </Box>
            {/* <div className='section1'>
                <div className='sectionTextContent'>

                    <div className='pDiv'>
                        <HeaderText1 text="Discover Our Chilli Products"></HeaderText1>
                        <p>
                            As a new user, you can easily navigate through our website to explore our wide range of chilli products. Once you've found the perfect chilli, you can easily check its price and learn about its exporting capabilities. It's simple and convenient!
                        </p>
                    </div>
                </div>

                <div style={{ flex: 2 }}>
                    <div>
                        <img
                            alt="img1"
                            src={img1}
                            style={{ margin: '0 16px' }}
                        />
                    </div>
                </div>
            </div> */}
            {/* <div className='section2'>


                <div style={{ flex: 2 }}>
                    <div>
                        <img
                            alt="img1"
                            src={img2}
                            style={{ margin: '0 16px' }}
                        />
                    </div>
                </div>
                <div className='sectionTextContent'>

                    <div className='pDiv' >
                        <HeaderText1 text="ChilliExpert and the Chilli Market"></HeaderText1>
                        <p>
                            As a new user, you can easily navigate through our website to explore our wide range of chilli products. Once you've found the perfect chilli, you can easily check its price and learn about its exporting capabilities. It's simple and convenient!
                        </p>
                    </div>
                </div>
            </div> */}

            {/* <div className='contact'>
                <div className='contactContainer'>
                    <HeaderText1 text="Contact us"></HeaderText1>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inbox" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <DraftsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Drafts" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>


            </div> */}


        </div>
    )
}

export default Home