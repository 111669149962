import React, { useEffect, useState } from "react";

import { styled, Box, Typography, Grid, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';

import { chilliDetails } from "../../data/chilliDetails";

import { tableCellClasses } from '@mui/material/TableCell';

import { useNavigate } from "react-router-dom";

import chillibg1 from '../../assets/chillibg1.jpeg'
import CertificateGrid from "../../Components/CertificateGrid/CertificateGrid";

// import { Image } from "@mui/icons-material";


// const Image = styled('img')({
//     padding: '15px 20px',
//     border: '1px solid #f0f0f0',
//     width: '100%',
//     height: '300px',
//     objectFit: 'cover',
//     objectPosition: '80% 100%'
// });

// const Component = styled(Box)`
//     margin-top: 55px;
//     background: #F2F2F2;
// `;

const Container = styled(Grid)(({ theme }) => ({
    background: '#FFFFFF',
    display: 'flex',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
        margin: 0
    }
}))

const RightContainer = styled(Grid)`
    text-align: justify;
    // margin-top: 50px;
    margin-bottom: 50px;
    & > p {
        margin-top: 10px;
    }
`;

// const StyleImgBg = styled.div`
//     text-align: justify;
// `;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));




const About = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        let chillidetails = chilliDetails()
        setProducts(chillidetails)
    }, [])

    return (
        <>

            <Box
                sx={{
                    backgroundImage: `url(${chillibg1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "black",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%", md: "40%" },
                        padding: { xs: 3, sm: 2, md: 20 },
                    }}
                >
                    <Box sx={{ background: "white", opacity: "0.8", justifyContent: "center" }}>
                        <Typography variant="h4" align="center" color="tomato">
                            About Us
                        </Typography>
                        <Typography variant="h3" align="center" pb={8}>
                            Dhruv Ayaansh Enterprises
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Container container spacing={2} p={5}>
                {/* <Grid xs={12}>
                    <Image src={chillibg1}/>
                </Grid> */}
                <Grid item lg={8} md={8} sm={8} xs={12} >
                    <Typography variant="body2" gutterBottom>
                        Dhruv Ayaansh Enterprises is a 2012 established organization committed to delivering premium quality products to its clients.
                        Based in Warangal (Telangana), the organization is involved in exporting varieties of dried red chili, chili seeds, and chili powder to different
                        countries. The offered products are unadulterated, authentic, full of flavor, and have good color. They are also made available at
                        reasonable prices. The organization is introduced in the market by Mr. Kancha Sravan. It is his valuable guidance and support that let the organization grow at an accelerated pace in this competitive market
                    </Typography>
                    <Typography variant="subtitle2" >
                        Our Vendor Base
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        We are linked up with some of the trusted and renowned vendors in the market who provide us with the best quality products to support us in keeping our supply chain continued throughout the year. We make sure that the chilies and chili powder that we procure from these vendors are precisely processed in a suitable condition in a contamination-free environment in compliance with the industry-set guidelines. Rigorous quality analysis is conducted at the vendors’ end so as to ensure the quality and authenticity of the products.
                    </Typography>
                    <Typography variant="subtitle2" >
                        Our Storage Unit
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        All of the procured products are stored in a temperature-controlled storage room having ample space. We pay special attention to maintaining the cleanliness and hygiene of this unit so as to protect our product stocks from moisture, fungus, microbes, pests, and other possible contaminating things.
                    </Typography>
                    <Typography variant="subtitle2" >
                        Why Us
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        We are fortunate enough that we are assisted by a team of efficient and energetic individuals who handle all of our business activities in a systematic manner. By offering the best quality products at cost-effective prices, maintaining transparency in our dealings, and delivering orders in the promised time period, we are attaining a high level of customer satisfaction.
                    </Typography>

                    <Table size="small" aria-label="a dense table" style={{ border: '1px solid #f0f0f0' }}>
                        <TableBody>
                            <StyledTableRow >

                                <TableCell component="th" scope="row">
                                    Name of Business
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Exporter
                                </TableCell>
                            </StyledTableRow>

                            <StyledTableRow align="right">
                                <TableCell component="th" scope="row">
                                    Number of Employees
                                </TableCell>
                                <TableCell >
                                    Below 20
                                </TableCell>
                            </StyledTableRow>



                            <StyledTableRow align="right">
                                <TableCell component="th" scope="row">
                                    Year of Establishment
                                </TableCell>
                                <TableCell >
                                    2012
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow align="right">
                                <TableCell component="th" scope="row">
                                    Market Covered
                                </TableCell>
                                <TableCell >
                                    World Wide
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow align="right">
                                <TableCell component="th" scope="row">
                                    Name of CEO
                                </TableCell>
                                <TableCell >
                                    Sravan Kancha
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow align="right">
                                <TableCell component="th" scope="row">
                                    Legal Status of Firm
                                </TableCell>
                                <TableCell >
                                    Individual (Sole proprietorship)
                                </TableCell>
                            </StyledTableRow>

                        </TableBody>
                    </Table>
                </Grid>
                <RightContainer item lg={4} md={4} sm={8} xs={12}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Products</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row) => (
                                <StyledTableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    onClick={() => navigate(`/product/${row.name}`)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </RightContainer>
            </Container>
            <CertificateGrid/>





        </>
    )

}

export default About