import './App.css';
import { Route,Routes ,Navigate,BrowserRouter as Router,HashRouter} from 'react-router-dom';
import Header from './Components/Header/Header';
import Home from './Pages/Home/Home';
import Products from './Pages/Products/Products';
import Product from './Pages/Product/Product';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Footer from './Components/Footer/Footer';




function App() {
  return (
    <div className='AppContainer'>
      <div className="App">
      <HashRouter>
      <Header />
      <Routes>
      <Route exact path='/home' Component={Home}/>
      <Route path='/products' Component={Products} />
      <Route path="/product/:name" Component={Product} />
      <Route path="/about" Component={About} />
      <Route path="/contact" Component={Contact} />
      <Route path="*" element={<Navigate to="/home" />} />
  
      </Routes>
      <Footer/>
      {/* <InActive/> */}
      </HashRouter>
      
      </div>
    </div>
  );
}

export default App;
