import React from "react";
import { Grid, Box ,Typography} from "@mui/material";
import iso from '../../assets/iso.png';
import fssai from '../../assets/fssai.jpeg';
import spicesboard from '../../assets/spices-board.png';
import apeda from '../../assets/apeda.jpeg';


const CertificateGrid = () => {
    return (
        <>
            <Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} >
                        <Typography variant="body" style={{
                            textAlign: 'left', color: '#030303',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            lineHeight: '18px',
                        }}>Certifications</Typography>
                        <Typography variant="h3" pb={2} style={{
                            textAlign: 'center', color: 'rgb(0, 126, 40)',
                            fontSize: '28px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            lineHeight: '52px',
                        }}>We are Certified & Recognized from</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6} >
                                <img
                                    alt="img1"
                                    src={iso}
                                    style={{ width: '50%', objectFit: 'contain' }}
                                />

                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6}  >
                                <img
                                    alt="img1"
                                    src={fssai}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6}  >
                                <img
                                    alt="img1"
                                    src={spicesboard}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6}  >
                                <img
                                    alt="img1"
                                    src={apeda}
                                    style={{ width: '70%', objectFit: 'contain' }}
                                />

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CertificateGrid