import s17teja from '../assets/s17teja.jpg';
import wonderhot from '../assets/wonderhot.jpg';
import chilli_341 from '../assets/341.jpg';
import chilli_5531 from '../assets/5531.jpg';
import sannamstemless_334 from '../assets/334sannamstemless.jpg';
import tomato from '../assets/tomato.jpg'
import s17tejastemless from '../assets/s17tejastemless.jpg';
import wonderhotstemless from '../assets/wonderhotstemless.jpg';
import s17tejafatki from '../assets/s17tejafatki.jpg';
import chilli334 from '../assets/334.jpg';
import chilli273 from '../assets/273.jpeg';
import chilli341stemless from '../assets/341stemless.jpeg';
import wonderhotfatki from '../assets/wonderhotfatki.jpeg';
import chilli5531stemless from '../assets/5531stemless.jpeg';
import chilli341fatki from '../assets/341fatki.jpeg';
import deepika from '../assets/deepika.png';
import c5 from '../assets/c5.jpeg'

export  const chilliDetails = ()=>{
    return [
        {
            id:'0001',
            name:'S17 Teja',
            imgUrl:s17teja,
            description:'Teja or S17 is one of the hottest varieties of red chillies produced in India. (GI tag not accorded yet.) The chilli is known and liked across the country for its fierce hot flavor and rich aroma. Southern India is the main region of Teja or S17 red chilli production. It has a capsaicin content of 0.50-0.70% making it more pungent and spicy. The huge demand for Oleoresin, a natural chilli extract, is mainly driving the export of Teja variety to various spice processing industries in several Asian countries.',
            countryOfOrigin:'India',
            quality:'BEST',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'75000-100000',
            colourInAsta:'40-60',
            length:'5-6 CM',
            breath:'0.8-1.3 CM',
            capsaicinContent: '0.50%-70%',
            moisture:'14%'
        },
        {
            name:'Wonder Hot',
            imgUrl:wonderhot,
            description:'Wonder Hot Red Chillies are popular over the globe due to its unique feature of long size, thick skin and low heat value. Wonder hot is widely used for making red chilli powder because the yield of the powder is very high and the colour is bright red which attract the consumers at first sight.',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'7000-9000',
            colourInAsta:'70-110',
            length:'15-16 CM',
            breath:'1-1.5 CM',
            capsaicinContent: '0.2%-0.23%',
            moisture:'14%'
        },
        {
            name:'341',
            imgUrl:chilli_341,
            description:'US-341 Red Chilli is a variety mainly preferred in the Red Chilli Powder manufacturers as the Colour value and Scoville Heat Units are better than the conventional varieties like the S4 - Sannam and S-17 - Teja. US-341 Red Chillies are majorly consumed by Masala Companies across the World.',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'35000-45000',
            colourInAsta:'80-110',
            length:'8-10 CM',
            breath:'1-1.2 CM',
            capsaicinContent: '0.30%-0.45%',
            moisture:'14%'
        },
        {
            name:'5531',
            imgUrl:chilli_5531,
            description:'Syngenta HPH-5531 is a hybrid hot pepper variety that is medium pungent and deep red in color. It is also known as Mirch, Menasinakai, Mirapa, and Milakay.',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thick',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'23000-30000',
            colourInAsta:'90-120',
            length:'6-11 CM',
            breath:'1-1.2 CM',
            capsaicinContent: '0.20%-40%',
            moisture:'14%'
        },
        {
            name:'334,SANNAM',
            imgUrl:chilli334,
            description:'Sannam also known as 334, S4 or sometimes as S10 is one of the most popular variants of the red chillies that is highly in demand because of its aroma, flavor and pungency. Nearly 50% of chilli production in India counts for Sannam dried red chillies.',
            countryOfOrigin:'India',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'25000-30000',
            colourInAsta:'50-70',
            length:'5-7 CM',
            breath:'1.2-2 CM',
            capsaicinContent: '0.2%-0.27%',
            moisture:'14%'
        },
        {
            name:'273',
            imgUrl:chilli273,
            description:'273 Wrinkle Dried Red Chillies is one of the most famous type of Chillies and has an overwhelming demand throughout the World for making Chilli Powder. It is also known as Semi Wrinkled Chilli .',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'20000-25000',
            colourInAsta:'80-110',
            length:'10-12 CM',
            breath:'1-1.1 CM',
            capsaicinContent: '0.5-0.726%',
            moisture:'14%'
        },
        {
            name:'Tomato',
            imgUrl:tomato,
            description:'The Warangal-only red-dried chapata chilli, also known as "Tomato Chapata Warangal Chilli," has a look similar to Mexican capsicum. It is grown in nearby fields that have easy access to water. The green capsicum is dried to create the red chilli variety. The Scoville Scale, a tool used to measure how hot a pepper is, gives this chilli an extremely low heat or pungency score. 4,000 to 8,000 SHU is the heat value, compared to 16 million SHU for pure capsaicin.',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thick',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'4000-5000',
            colourInAsta:'60-90',
            length:'5-7 CM',
            breath:'3-5 CM',
            capsaicinContent: '0.1-0.26%',
            moisture:'14%'
        },
        {
            name:'C5,ENDO5',
            imgUrl:c5,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thick',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'4000-5000',
            colourInAsta:'80-110 max',
            length:'8-10 CM',
            breath:'1-1.5 CM',
            capsaicinContent: '0.30%-0.45%',
            moisture:'14%'
        },
        {
            name:'DEEPIKA , INDICA',
            imgUrl:deepika,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thick',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'15000-200000',
            colourInAsta:'70-100',
            length:'10-12CM',
            breath:'1.5-2 CM',
            capsaicinContent: '0.2-0.4%',
            moisture:'14%'
        },
        {
            name:'Teja Stemless',
            imgUrl:s17tejastemless,
            description:'The S17 Teja chilli is the most spiciest variety available in India. The red factor and the compelling taste in our food is enhanced by Teja S17. The major world cuisines like Chinese, American, Thai, Italian, Japanese and Indian use this quality to enrich the taste, colour and smell of the food. It is also used in the making of chilli powder and is rich in various vitamins and minerals.',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'75000-100000',
            colourInAsta:'40-60',
            length:'4-5 CM',
            breath:'0.8-1.3 CM',
            capsaicinContent: '0.50%-70%',
            moisture:'14%'
        },
        {
            name:'Wonder Hot Stemless',
            imgUrl:wonderhotstemless,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'70000-90000',
            colourInAsta:'70-110',
            length:'12-13 CM',
            breath:'1-1.5 CM',
            capsaicinContent: '0.2%-0.23%',
            moisture:'14%'
        },
        {
            name:'341 Stemless',
            imgUrl:chilli341stemless,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'35000-45000',
            colourInAsta:'80-110',
            length:'5-6 max',
            breath:'1-1.2 CM',
            capsaicinContent: '0.30%-0.45%',
            moisture:'14%'
        },
        {
            name:'334,SANNAM Stemless',
            imgUrl:sannamstemless_334,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'25000-100000 (Heat)',
            colourInAsta:'50-70',
            length:'4-5 CM',
            breath:'1.1-2 CM',
            capsaicinContent: '0.2-0.27%',
            moisture:'14%'
        },
        {
            name:'5531 Stemless',
            imgUrl:chilli5531stemless,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'Red',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thick',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'23000-30000',
            colourInAsta:'90-120',
            length:'7-9 CM',
            breath:'1-1.2 CM',
            capsaicinContent: '0.20-0.40%',
            moisture:'14%'
        },
        {
            name:'Teja fatki',
            imgUrl: s17tejafatki,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'YELLOWISH RED',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'40000-450000',
            colourInAsta:'15-20',
            length:'3-5 CM',
            breath:'0.8-1 CM',
            capsaicinContent: '0.2-0.5%',
            moisture:'14%'
        },
        {
            name:'Wonder hot fatki',
            imgUrl:wonderhotfatki,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'YELLOWISH RED',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'2000-4000',
            colourInAsta:'20-40',
            length:'10-13 CM',
            breath:'1-1.2 CM',
            capsaicinContent: '0.1-0.2%',
            moisture:'14%'
        },
        {
            name:'341 fatki',
            imgUrl:chilli341fatki,
            description:'',
            countryOfOrigin:'India',
            quality:'Best',
            color:'YELLOWISH RED',
            cultivationType:'Organic',
            dryingProcess:'Sun Dry',
            skin:'Thin',
            Taste:'Spicy',
            businessType:'Exporter',
            specialities:'Rich in Taste Good Quality',
            packingType:'10,25,35,40 KG JUTE/PP',
            pungencyInSHU:'15000-20000',
            colourInAsta:'40-60',
            length:'5-7 CM',
            breath:'1-1.6 CM',
            capsaicinContent: '0.2-0.3%',
            moisture:'14%'
        }

]
}