import { useEffect } from 'react';
import { Button, Box, styled } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import EmailIcon from '@mui/icons-material/Email';


const LeftContainer = styled(Box)(({ theme }) => ({
    minWidth: '50%',
    // padding: '40px 0 0 80px',
    [theme.breakpoints.down('md')]: {
        padding: '20px 40px'
    }
}))

const Image = styled('img')({
    padding: '15px 20px',
    border: '1px solid #f0f0f0',
    width: '60%',
    
});

const StyledButton = styled(Button)`
    width: 50%;
    border-radius: 2px;
    height: 50px;
    color: #FFF;
`;

const ActionItem = ({ product }) => {


    useEffect(()=>{
      
    },[])



    return (
        <LeftContainer>
            {
                product && product.imgUrl ? <Image src={product.imgUrl} />:null
            }
            <br />
            
            <StyledButton   style={{background: '#fb641b'}} variant="contained"><WhatsAppIcon/> <a href="https://api.whatsapp.com/send?phone=919701801908" target={"_blank"} style={{color:'white'}}>Send  Message </a> </StyledButton>
        </LeftContainer>
    )
}

export default ActionItem;
