import React from "react";
import { Grid, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import  './Footer.css'

const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ backgroundColor: "#313131", marginTop: '10px' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={5}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Typography align="left" variant="button" display="block" color={"white"} gutterBottom style={{ fontSize: '22px' }}>
                            Useful Links
                        </Typography>
                        <div style={{ textAlign: 'left', color: 'white', marginLeft: '15px' }}>
                            <Typography variant="body2" className="typographyLink" gutterBottom onClick={() => navigate('/products')} style={{ cursor: 'pointer' }}>
                                <ChevronRightIcon /> Products
                            </Typography>
                            <Typography variant="body2" className="typographyLink" gutterBottom onClick={() => navigate('/about')} style={{ cursor: 'pointer' }}>
                                <ChevronRightIcon />About
                            </Typography>
                            <Typography variant="body2" className="typographyLink" gutterBottom onClick={() => navigate('/contact')} style={{ cursor: 'pointer' }}>
                                <ChevronRightIcon />Contact US
                            </Typography>
                        </div>

                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="button" align="left" display="block" color={"white"} gutterBottom style={{ fontSize: '22px' }}>
                            Dhruv Ayaansh Enterprises
                        </Typography>
                        <div style={{ textAlign: 'left', color: 'white', marginLeft: '15px' }}>
                            <Typography variant="body2" gutterBottom>11-14-131, Road No. 15A, "O" City,</Typography>
                            <Typography variant="body2" gutterBottom>Warangal, TS - 506002, INIDA.</Typography>
                            <Typography variant="body2" gutterBottom>GST No: 36HISPK5493J1ZA</Typography>
                        </div>



                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={12}>
                        <Typography variant="button" align="left" display="block" color={"white"} gutterBottom style={{ fontSize: '22px' }}>
                            contact
                        </Typography>
                        <div style={{ textAlign: 'left', color: 'white', marginLeft: '15px' }}>
                            <Typography variant="body2" className="typographyLink" gutterBottom>  <PhoneIcon /><a href="tel:+919701801908" style={{ color: '#989898' }}>+91 9701801908</a> </Typography>
                            <Typography variant="body2" className="typographyLink" gutterBottom><EmailIcon /> <a href="mailto:dhruvayaanshep@gmail.com" style={{ color: '#989898' }}>dhruvayaanshep@gmail.com</a></Typography>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '25px' ,color:'#989898'}}>
                        <Typography variant="body2" pt={9} color={"#989898"} gutterBottom style={{ borderTop: "1px solid white" }}> Copyright © 2024 Dhruv Ayaansh Enterprises. All Rights Reserved. | <a href="tel:+919642020570" style={{ color: '#989898' }}>Website Designed by Shiva Prasad</a> </Typography>
                    </Grid>
                </Grid>

            </div>

        </>
    )
}

export default Footer